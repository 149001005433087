import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import VerticalCard from '../VerticalCard';

const useStyles = makeStyles({
  container: {
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      marginTop: 40,
    },
  },
  card: {
    padding: '20px 0px 0px 0px',
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  subHeader: {
    marginTop: 20,
    marginBottom: 40,
    padding: '0px 80px 0px 160px',
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      marginTop: 40,
    },
  },
});

export default () => {
  const classes = useStyles();
  const management = useStaticQuery(query).first.items;
  const board = useStaticQuery(query).second.items;

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <h3 className={classes.subHeader}>Management</h3>
        {management.map(
          ({
            image: { fluid: imgFluid },
            name,
            role,
            description,
            moreDescription,
            ...rest
          }) => (
            <Grid sm={10} item className={classes.card} key={name}>
              <VerticalCard
                direction="row-reverse"
                alignItems="flex-start"
                title={name}
                subTitle={role}
                description={description && description.description}
                moreDescription={
                  moreDescription && moreDescription.moreDescription
                }
                imgFluid={imgFluid}
                {...rest}
              />
            </Grid>
          )
        )}
      </Grid>
      <Grid container direction="column" className={classes.container}>
        <h3 className={classes.subHeader}>Board</h3>
        {board.map(
          ({
            image: { fluid: imgFluid },
            name,
            role,
            description,
            moreDescription,
            ...rest
          }) => (
            <Grid sm={10} item className={classes.card} key={name}>
              <VerticalCard
                direction="row-reverse"
                alignItems="flex-start"
                title={name}
                subTitle={role}
                description={description && description.description}
                moreDescription={
                  moreDescription && moreDescription.moreDescription
                }
                imgFluid={imgFluid}
                {...rest}
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};
const query = graphql`
  query ManagementSubpage {
    first: contentfulOrderedList(
      listId: { eq: "/about-us?subpage=management" }
    ) {
      items {
        ... on ContentfulEmployee {
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          role
          phone
          email
          description {
            description
          }
          moreDescription {
            moreDescription
          }
        }
      }
    }
    second: contentfulOrderedList(listId: { eq: "/about-us?subpage=board" }) {
      items {
        ... on ContentfulEmployee {
          image {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          role
          description {
            description
          }
          moreDescription {
            moreDescription
          }
        }
      }
    }
  }
`;
