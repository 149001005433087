import React, { useState } from 'react';
import { string, shape } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from '@material-ui/core';
import Img from 'gatsby-image';
// import arrowDownIcon from '../../images/arrowDownBlack.png';
import arrowDownCoral from '../../images/arrowDownCoral.png';
import { COLOR_RED } from '../../utils/constants';
import { formatPhoneNumber } from '../../utils/functions';

const useStyle = makeStyles(() => ({
  container: {
    '@media (max-width:750px)': {
      flexDirection: 'column-reverse !important',
    },
  },
  expansion: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    flexFlow: 'column-reverse',
    '&:before': {
      display: 'none',
    },
    '&:expanded': {
      margin: 0,
    },
  },
  rootSummary: {
    height: '30px !important',
    padding: 0,
  },
  expansionExpanded: {
    margin: '0 !important',
  },
  textNoMargin: {
    margin: 0,
  },
  spacialH: {
    fontSize: '1.5em',
  },
  contentSummary: {
    margin: '0 !important',
  },
  details: {
    padding: 0,
  },
  subTitle: {
    fontSize: '0.9em',
    margin: '6px 0 12px',
    fontWeight: 600,
    textDecoration: 'none',
  },
  containerArrowDown: {
    marginLeft: 10,
    width: 20,
    transform: ({ expanded }) => expanded && 'rotate(180deg)',
  },
  imageBlk: {
    filter: 'grayscale(100%)',
    '@media (max-width:600px)': {
      // marginLeft: -40,
    },
  },
}));

const VerticalCard = ({
  title,
  subTitle,
  imgFluid,
  description,
  moreDescription,
  email,
  phone,
  link,
  linkText,
  direction,
  alignItems,
  justify,
}) => {
  const [expanded, setExpanded] = useState(false);
  const styles = useStyle({ expanded });
  return (
    <Grid
      container
      direction={direction}
      alignItems={alignItems}
      justify={justify}
      spacing={4}
      className={styles.container}
    >
      <Grid item sm={6}>
        <span className={styles.spacialH}>
          <h3 className={styles.textNoMargin}>{title}</h3>
        </span>
        {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        {phone && (
          <a
            href={`tel:${phone.replace(/\s/g, '')}`}
            className={styles.subTitle}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: COLOR_RED }}
          >
            {`Tel: ${formatPhoneNumber(phone)}`}
          </a>
        )}
        <br />
        {email && (
          <a
            href={`mailto:${email}`}
            className={styles.subTitle}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: COLOR_RED }}
          >
            {`Email: ${email}`}
          </a>
        )}
        {description && (
          <p style={{ marginTop: '8px 0 16px' }}>{description}</p>
        )}
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {linkText || 'Read more'}
          </a>
        )}
        {moreDescription && (
          <ExpansionPanel
            classes={{
              root: styles.expansion,
              expanded: styles.expansionExpanded,
            }}
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
          >
            <ExpansionPanelSummary
              classes={{
                content: styles.contentSummary,
                root: styles.rootSummary,
              }}
            >
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <p style={{ color: COLOR_RED }}>
                    {expanded ? 'Read less' : 'Read more'}
                  </p>
                </Grid>
                <Grid item className={styles.containerArrowDown}>
                  <img src={arrowDownCoral} alt="vow arrow more" />
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: styles.details }}>
              <p>{moreDescription}</p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </Grid>
      {imgFluid && (
        <Grid item sm={4}>
          <Img fluid={imgFluid} alt="" className={styles.imageBlk} />
        </Grid>
      )}
    </Grid>
  );
};

VerticalCard.propTypes = {
  title: string.isRequired,
  subTitle: string,
  moreDescription: string,
  description: string,
  imgFluid: shape().isRequired,
  link: string,
  direction: string,
  alignItems: string,
  justify: string,
  linkText: string,
  email: string,
  phone: string,
};

VerticalCard.defaultProps = {
  subTitle: null,
  moreDescription: null,
  link: null,
  direction: 'row',
  alignItems: 'center',
  justify: 'flex-start',
  linkText: null,
  email: null,
  phone: null,
  description: null,
};

export default VerticalCard;
