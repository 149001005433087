import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import sunsetCruise from '../../images/sunsetCruise2.jpg';
import crabby from '../../images/crabby.jpg';
import device from '../../images/device.jpg';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 40,
    '@media (max-width:600px)': {
      marginTop: 40,
    },
  },
  container2: {
    justifyContent: 'space-between',
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 20,
      marginBottom: 0,
    },
  },
  imageGrid: {
    marginTop: 40,
    padding: '20px 100px 0px 0px',
    '@media (max-width:900px)': {
      padding: '20px 50px 0px 0px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
  textGrid: {
    marginTop: 40,
    padding: '20px 100px 0px 0px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 70px 0px 0px',
    },
    '@media (max-width:900px)': {
      padding: '20px 50px 0px 0px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
});

export default () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={crabby} alt="hands" />
      </Grid>
      <Grid item sm={6} className={classes.textGrid}>
        <h2 style={{ marginBlockStart: 0 }}>OUR BUSINESS</h2>
        <p>
          Vow and its subsidiaries Scanship, C.H. Evensen and Etia are
          passionate about preventing pollution. The company’s world leading
          solutions convert biomass and waste into valuable resources and
          generate clean energy for a wide range of industries.
        </p>
        <p>
          Advanced technologies and solutions from Vow enable industry
          decarbonisation and material recycling. Biomass, sewage sludge,
          plastic waste and end-of-life tyres can be converted into clean
          energy, low carbon fuels and renewable carbon that replace natural
          gas, petroleum products and fossil carbon. The solutions are scalable,
          standardised, patented, and thoroughly documented, and the company’s
          capability to deliver is well proven.
        </p>
        <p>
          The company is a cruise market leader in wastewater purification and
          valorisation of waste. It also has strong niche positions in food
          safety and robotics, and in heat-intensive industries with a strong
          decarbonising agenda.
        </p>
        <p>
          Located in Oslo, the parent company Vow ASA is listed on the Oslo
          Stock Exchange (ticker VOW).
        </p>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={sunsetCruise} alt="Cruise ship" />
      </Grid>
      <Grid item sm={6} className={classes.textGrid}>
        <h2 style={{ marginBlockStart: 0 }}>HISTORY</h2>
        <p>
          Vow came to light in 2020 when the company Scanship Holding ASA
          decided to change name to Vow ASA. During the preceding year, the
          company had undergone a remarkable and significant transformation,
          most notably by the acquisition of the French engineering company Etia
          Technologies. The new name reflects the group's commitment to deliver
          not only solutions for cleaner oceans, but also solutions for
          valorisation of waste on land.
        </p>
        <p>
          Dating back to 1993, Scanship soon became a preferred provider of
          wastewater treatment solutions for the cruise industry. At the end of
          2019, the company had delivered 253 systems and 119 more were on
          order. It had by then also entered the aquaculture market.
        </p>
        <p>
          The parent company Scanship Holding ASA was originally incorporated in
          2011 and listed on the Oslo Axess, a marketplace authorised and fully
          regulated by the Oslo Stock Exchange, in 2014. In 2019, the Scanship
          shares were transferred to the Oslo Stock Exchange.
        </p>
        <p>
          Created in 1989, Etia is a European technology leader to valorise
          biomass residues and waste into renewable products, chemicals and
          fossil free energy through pyrolysis solutions. Etia offers proven
          solutions for turning waste into valuable green products and climate
          friendly energy.
        </p>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={device} alt="Mechanical device" />
      </Grid>
      <Grid item sm={6} className={classes.textGrid}>
        <h2 style={{ marginBlockStart: 0 }}>OUR SOLUTIONS</h2>
        <p>
          OUR SOLUTIONS include wastewater purification, food and industrial
          waste processing, production of renewable materials and products,
          molecules (methane and hydrogen), and fuels from organic waste and
          biomass, valorization of non-recyclable plastic and polymers,
          sterilization of food and electrified high temperature processing of
          minerals.
        </p>
        <p>
          OUR KEY MARKETS for waste valorization, prevention of pollution and
          decarbonization include cruise, biogas, metallurgical, minerals,
          plastic to energy, end-of-life tires, power to heat, waste management,
          agricultural, aquaculture and food processing.
        </p>
      </Grid>
    </Grid>
  );
};
