import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate } from '@reach/router';
import H1 from '../../components/header1';
import {
  FirstTab,
  SecondTab,
  ThirdTab,
  FourthTab,
} from '../../components/aboutUsTabs';
import { TabMenu, TabPanel } from '../../components/Tabs';
import { SUB_PAGES } from '../../utils/constants';
import { isBrowser, stripTrailingSlash } from '../../utils/functions';

const { titles, aboutUsTabs } = SUB_PAGES.ABOUT_US;

const getIndexFromPathName = pathname => {
  if (pathname) {
    const trimmed = stripTrailingSlash(pathname);
    const index = aboutUsTabs.findIndex(element => element.tabLink === trimmed);
    return index !== -1 ? index : 0;
  }
  return 0;
};

const FIRST_TAB_VALUE = aboutUsTabs[0].tabValue;
const SECOND_TAB_VALUE = aboutUsTabs[1].tabValue;
const THIRD_TAB_VALUE = aboutUsTabs[2].tabValue;
const FOURTH_TAB_VALUE = aboutUsTabs[3].tabValue;

const Index = () => {
  const theme = useTheme();
  const [location, setLocation] = useState('');
  const navigate = useNavigate();
  // const location = isBrowser() && window.location.pathname;
  const [selectedTab, setSelectedTab] = useState(
    getIndexFromPathName(location)
  );

  // Can have empty dep array
  useEffect(() => {
    if (isBrowser()) {
      setLocation(window.location.pathname);
    }
  }, [selectedTab]);

  const handleChange = (_, index) => {
    setSelectedTab(index);
    const urlToPush = aboutUsTabs[index].tabLink;
    if (isBrowser()) {
      navigate(urlToPush);
    }
    // Above possible solution to navigation to tabs with url including subpages. Here old code:
    // if (isBrowser()) {
    // window.history.pushState(null, null, urlToPush);
    // setLocation(urlToPush); // did add, no help
    // }
  };

  const handleChangeIndex = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (isBrowser()) {
      setSelectedTab(getIndexFromPathName(location));
    }
  }, [location]);

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Grid container direction="column" style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Container maxWidth="xl">
            <H1 title="ABOUT US" />
          </Container>
        </Grid>
        <TabMenu value={selectedTab} onChange={handleChange} tabs={titles} />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selectedTab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={FIRST_TAB_VALUE} currentValue={selectedTab}>
            <FirstTab />
          </TabPanel>
          <TabPanel value={SECOND_TAB_VALUE} currentValue={selectedTab}>
            <SecondTab />
          </TabPanel>
          <TabPanel value={THIRD_TAB_VALUE} currentValue={selectedTab}>
            <ThirdTab />
          </TabPanel>
          <TabPanel value={FOURTH_TAB_VALUE} currentValue={selectedTab}>
            <FourthTab />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Container>
  );
};

export default Index;
