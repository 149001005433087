import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import { shape, string } from 'prop-types';
import Img from 'gatsby-image';
import { COLOR_CORAL } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none !important',
  },
  title: {
    fontSize: '1em',
    lineHeight: '1.4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    fontWeight: 'bold',
  },
  readMore: {
    fontSize: '0.8em',
    textAlign: 'center',
    display: 'flex',
    lineHeight: '1em',
  },
  line: {
    display: 'block',
    width: 30,
    height: '2px',
    border: 0,
    borderTop: `4px solid${COLOR_CORAL}`,
    margin: '0.5em 0',
    padding: 0,
  },
}));

const VoicesOfVowCard = ({ shortDescription, fluid, slug }) => {
  const classes = useStyles();
  return (
    <Link to={`/voices-of-vow/${slug}`} className={classes.link}>
      <Img fluid={fluid} />
      <div className={classes.flexContainer}>
        <p className={classes.title}>{shortDescription}</p>
        <hr className={classes.line} />
        <div className={classes.readMore}>
          <span>Read more</span>
        </div>
      </div>
    </Link>
  );
};

VoicesOfVowCard.propTypes = {
  shortDescription: string.isRequired,
  fluid: shape().isRequired,
  slug: string.isRequired,
};

export default VoicesOfVowCard;
