import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import employee from '../../images/employerWork.jpg';
import FormCV from '../formCV';
import { COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    '@media (max-width:600px)': {
      // flexDirection: 'column-reverse',
      marginTop: 0,
      marginBottom: 0,
    },
  },
  imageGrid: {
    padding: '0px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 40px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '0px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 20px 20px 20px',
    },
  },
  vacWrapper: {
    '@media (max-width:600px)': {
      width: '96%',
      marginBottom: 40,
    },
    marginTop: 50,
  },
  vacDetailContainer: {
    display: 'flex',
  },
  vacDetailTitle: {
    margin: 0,
    fontWeight: 'bold',
    width: 100,
  },
  vacDetailText: {
    margin: 0,
    paddingTop: 2,
  },
  link: {
    fontSize: 15,
    color: COLOR_RED_VISITED,
    textDecoration: 'none',
  },
  formAndText: {
    maxWidth: 550,
    '@media (max-width:600px)': {
      paddingLeft: '4%',
      paddingRight: '4%',
      width: '96%',
    },
  },
  formHeader: {
    marginTop: 40,
    marginBottom: 20,
    '@media (max-width:600px)': {
      paddingLeft: '4%',
      paddingRight: '4%',
    },
  },
  form: {
    maxWidth: 550,
    '@media (max-width:1200px)': {
      maxWidth: 400,
    },
    '@media (max-width:600px)': {
      paddingLeft: '4%',
      paddingRight: '4%',
      width: '96%',
    },
  },
  unleashHeader: {
    marginBlockStart: 0,
    paddingBottom: 20,
    '@media (max-width:600px)': {
      paddingLeft: '4%',
      paddingRight: '4%',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const workWithUsData = useStaticQuery(query).allContentfulPage.edges[0].node;
  const pageSubHeader = workWithUsData.subTitle.subheaderText;
  const pageBodyText =
    workWithUsData.textColumn.paragraphText.childMarkdownRemark.html;

  const vacancyData = useStaticQuery(query).allContentfulVacancyHr.edges;

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, marginTop: 100 }}
    >
      <Grid
        container
        direction="column"
        styles={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          {vacancyData.length < 2 ? (
            <div>
              <h3 style={{ marginBlockStart: 0 }}>{pageSubHeader}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageBodyText,
                }}
              />
            </div>
          ) : (
            <h3
              style={{
                marginTop: 0,
                marginBottom: 10,
              }}
            >
              Available positions
            </h3>
          )}
          {vacancyData.map((vacancy, i) => {
            return vacancy.node.jobTitle ===
              'Example, do not remove or unpublish' ? (
              // eslint-disable-next-line react/jsx-indent
              <div />
            ) : (
              <div className={classes.vacWrapper}>
                <hr />
                <h4 style={{ paddingTop: '8px' }}>{vacancy.node.jobTitle}</h4>
                <p className={classes.vacDesc}>
                  {vacancy.node.shortDescription.shortDescription}
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 6,
                  }}
                >
                  <div className={classes.vacDetailContainer}>
                    <p className={classes.vacDetailTitle}>Area</p>
                    <p className={classes.vacDetailText}>{vacancy.node.area}</p>
                  </div>
                  <div className={classes.vacDetailContainer}>
                    <p className={classes.vacDetailTitle}>Location</p>
                    <p className={classes.vacDetailText}>
                      {vacancy.node.workLocation}
                    </p>
                  </div>
                  <div className={classes.vacDetailContainer}>
                    <p className={classes.vacDetailTitle}>Posted</p>
                    <p className={classes.vacDetailText}>
                      {vacancy.node.datePublished}
                    </p>
                  </div>
                </div>
                <Link
                  to={`/about-us/work-with-us/${vacancy.node.slug}`}
                  className={classes.link}
                >
                  More info
                </Link>
                {i === vacancyData.length - 2 && (
                  <div style={{ paddingTop: 30 }}>
                    <hr />
                  </div>
                )}
              </div>
            );
          })}
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <img src={employee} alt="employee" />
          {vacancyData.length > 1 && (
            <div style={{ marginTop: 80 }}>
              <h3 className={classes.unleashHeader}>{pageSubHeader}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageBodyText,
                }}
                className={classes.formAndText}
              />
            </div>
          )}
          <h4 className={classes.formHeader}>Submit open application</h4>
          <div className={classes.form}>
            <FormCV
              name="work"
              // action="/src/pages/thank-you"
              className={classes.form}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export const query = graphql`
  query workQuery {
    allContentfulPage(filter: { pageName: { eq: "Work With Us" } }) {
      edges {
        node {
          title {
            headerText
          }
          subTitle {
            subheaderText
          }
          textColumn {
            paragraphText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    allContentfulVacancyHr(sort: { fields: [datePublished], order: DESC }) {
      edges {
        node {
          jobTitle
          shortDescription {
            shortDescription
          }
          area
          workLocation
          createdAt
          datePublished(formatString: "MMMM Do, YYYY")
          slug
        }
      }
    }
  }
`;
