import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import SEO from '../../components/seo';
import VoicesOfVowCard from '../../components/voices-of-vow/VoicesOfVowCard';
import voices from '../../images/voices.jpg';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    padding: '0px 160px 40px 160px',
    '@media (max-width:1024px)': {
      padding: '20px 100px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 50px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      marginTop: 0,
    },
  },
  headContainer: {
    justifyContent: 'space-between',
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 20,
      marginBottom: 0,
    },
    borderBottom: '1px solid grey',
  },
  textGrid: {
    alignSelf: 'center',
    padding: '0px 0px 40px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '0px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  imageGrid: {
    marginTop: 100,
    padding: '0px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
      marginTop: 40,
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  separatorLine: {
    border: '1px solid grey',
  },
}));

export default () => {
  const classes = useStyles();
  const data = useStaticQuery(query);
  const posts = data.allContentfulVoicesOfVowPost.edges;

  return (
    <>
      {/* <SEO title="Vow ASA | Voices of Vow" ogimage={voices} /> TODO: SEO*/}
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Grid container className={classes.headContainer}>
          <Grid item sm={6} className={classes.textGrid}>
            <h3 style={{ marginBottom: 10 }}>Meet our team</h3>
            <p>
              We are a highly diversified team in terms of background,
              competence, and experience, joined by our passion to prevent
              pollution and provide world leading solutions that convert biomass
              and waste into valuable resources and generate clean and CO2
              neutral energy.
            </p>
            <p>
              Meet our team and learn more about us here. And – don’t forget to
              follow us on LinkedIn #VoicesofVow
            </p>
          </Grid>

          <Grid item sm={6} className={classes.imageGrid}>
            <img src={voices} alt="employees" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className={classes.container}>
          {posts.map((post, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              style={{ marginTop: 20 }}
              key={index}
            >
              <VoicesOfVowCard
                shortDescription={post.node.description}
                fluid={post.node.image.fluid}
                slug={post.node.slug}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export const query = graphql`
  query voicesQuery {
    allContentfulVoicesOfVowPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          slug
          description
          publishDate(formatString: "MMMM Do, YYYY")
          image {
            fluid(quality: 90, maxWidth: 300, maxHeight: 200) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
